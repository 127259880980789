import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";

export const SideTriplets = styled.div`
  @media ${breakpoints.desktop} {
    width: 300px;
    height: 349px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
`;
