import styled from "styled-components";
import colors from "./colors";

const titleStyle = `
  text-decoration: none;
  text-align: left;
  color: #333;
  line-height:1.2em;
  &:hover {
    color: ${colors.shnBlue}
  }
`;

export const UnStyledLink = styled.a`
  ${titleStyle}
`;

export const UnStyledTitleLink = styled(UnStyledLink)`
  padding-right: 0.5em;
`;

export const UnStyledTitleNonLink = styled.span`
  ${titleStyle}
  padding-right: 0.5em;
  cursor: pointer;
`;
