import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsTripletPlaceholder
  from "../../News/NewsTriplet/NewsTripletPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import resolvePath from "../../../utils/resolvePath";
import {IMAGE_ONE_THIRD} from "../../../utils/variables";
import {PUBLI_REPORTS} from "../../../utils/constants";
import BlockTitle from "../BlockTitle";

const NewsTriplet = lazy(() => import('../../News/NewsTriplet'));

export default function SextupleImage({ data = [], title }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 410 : 920;
  const EmptyEntry = () => {
    return (
      <div>
        <Block >
          <NewsTripletPlaceholder />
          <NewsTripletPlaceholder />
          <NewsTripletPlaceholder />
        </Block>
        <Block>
          <NewsTripletPlaceholder />
          <NewsTripletPlaceholder />
          <NewsTripletPlaceholder />
        </Block>
      </div>
    )
  }

  if (data.length === 0) {
    return <EmptyEntry />
  }

  const Entry = ({ resultData }) => {
    return resultData.map(node => <NewsTriplet
      key={`news_${node.id}`}
      title={node.title}
      image={resolvePath(node, IMAGE_ONE_THIRD)}
      link={node.link}
      author={node.author}
      imageAlt={node.image_alt}
      imageTitle={node.image_title}
      newsType={node.shn_type}
      catchLine={node?.catchLine}
      paywall={node.paywall}
      flag={node.flag}
      publiReport={node.shn_type === PUBLI_REPORTS}
      showAuthor={node.showAuthor}
    />);
  }

  const EntriesWrapped = () => {
    return (
      <>
        <Block>
          <Entry resultData={[data[0], data[1], data[2]]} />
        </Block>
        <Block>
          <Entry resultData={[data[3], data[4], data[5]]} />
        </Block>
      </>
    );
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<EmptyEntry />}>
        <BlockTitle title={title} />
        <EntriesWrapped />
      </Suspense>
    </LazyLoad>
  );
}
