import breakpoints from "./breakpoints";

export const blockMarginMobileInt = 40;
export const blockMarginDesktopInt = 50;

export const blockMarginMobile = `${blockMarginMobileInt}px`;
export const blockMarginBottom = `${blockMarginDesktopInt}px`;

export const mobileItemMargin = `
  margin-bottom: ${blockMarginMobile};

  @media ${breakpoints.tablet} {
    margin-bottom: 0;
  }
`;

export const desktopBlockMargin = `
  @media ${breakpoints.tablet} {
    margin-bottom: ${blockMarginBottom};
  }
`;

export const tabletItemMargin = `
  @media ${breakpoints.tabletOnly} {
    margin-bottom: ${blockMarginMobile};
  }
`;
