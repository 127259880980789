import {ImageHover} from "../components/Images/ImageStyles";
import colors from "./colors";
import {animations} from "./animations";

export const blockHover = `
  h2 a,
  h2 span {
    ${animations.image.hover}
  }
  &:hover {
    img {
      ${ImageHover}
    }
    h2 span,
    h2 a {
      color: ${colors.shnBlue}
    }
  }
`;
