import React from "react";
import {FixBlock} from "../../Block";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";

export default function NewsTextSinglePlaceholder() {
  return (
    <FixBlock>
      <TitlePlaceholder />
    </FixBlock>
  )
}
