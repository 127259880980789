import styled from "styled-components";
import {blockBorder} from "./borders";
import { mobileItemMargin, tabletItemMargin } from "./margins";
import breakpoints from "./breakpoints";
import {animations} from "./animations";
import {blockHover} from "./blockHover";

export const TripletItem = styled.div`
  display: inline-block;
  max-width: 360px;
  height: auto;
  &:before {
    ${blockBorder}
    width: 100%;
    display: block;
    content: '';
    margin-bottom: -1px;
  }
  @media ${breakpoints.mobileLarge} {
    width: 360px;
    max-width: initial;
  }
  ${mobileItemMargin}
  ${tabletItemMargin}
  @media ${breakpoints.desktop} {
    width: 300px;
  }
  @media ${breakpoints.tabletOnly} {
    width: 100%;
  }
  ${animations.image.unHover}
  ${blockHover}
`;

export const TripletInfoWrapper = styled.div`
  display: flex;
`;

export const TripletItemInner = styled.div`
  @media ${breakpoints.tabletOnly} {
    display: flex;
  }
`;

export default TripletItem;
