export const animations = {
  all: {
    elastic: `transition: all cubic-bezier(.175, .885, .32, 1.275) 0.2s;`,
    swift: `transition: all cubic-bezier(.62,.28,.23,.99) 0.2s;`,
    subtle: `transition: all cubic-bezier(.53,.11,.88,.58) 0.8s;`,
  },
  transform: {
    elastic: `transition: transform cubic-bezier(.175, .885, .32, 1.275) 0.2s;`,
    swift: `transition: transform cubic-bezier(.62,.28,.23,.99) 0.2s;`,
    subtle: `transition: transform cubic-bezier(.53,.11,.88,.58) 0.8s;`,
    standard: `transition: transform ease-in-out 0.2s;`,
  },
  image: {
    hover: `transition: all ease-in 0.2s;`,
    unHover: `transition: all ease-out 0.2s;`,
  }
}
