import React from "react";
import {GalleryBlockStyle, GalleryBlockInner} from "./GalleryStyle";
import GalleryItemPlaceholder from "./GalleryItemPlaceholder";

export default function GalleryBlockPlaceholder() {
  return (
    <GalleryBlockStyle>
      <h2>Bildergalerien</h2>
      <GalleryBlockInner>
        <GalleryItemPlaceholder />
        <GalleryItemPlaceholder />
        <GalleryItemPlaceholder />
      </GalleryBlockInner>
    </GalleryBlockStyle>
  )
}
