import React, {Suspense, lazy} from "react";
import LazyLoad from "react-lazyload";
import {FixBlock} from "../../Block";
import {
  MostReadIndex,
  MostReadItemStyle,
  NewsMostReadBlockTitle
} from "./NewsMostReadStyles";
import {ItemTitleSmall} from "../../../utils/titles";
import {TitlePlaceHolder} from "../../Placeholders/TitleStyle";

const NewsMostReadBlock = lazy(() => import('./NewsMostReadBlock'));

export default function NewsMostRead() {
  const Entries = () => {
    return [...Array(6)].map((e, index) => {
      return (
        <MostReadItemStyle>
          <MostReadIndex>{index + 1}</MostReadIndex>
          <ItemTitleSmall>
            <TitlePlaceHolder/>
          </ItemTitleSmall>
        </MostReadItemStyle>
      );
    });
  }

  const EmptyBlock = () => {
    return (
      <FixBlock>
        <NewsMostReadBlockTitle>Meistgelesen</NewsMostReadBlockTitle>
        <Entries />
      </FixBlock>
    );
  }

  return (
    <LazyLoad height={480}>
      <Suspense fallback={<EmptyBlock />}>
        <FixBlock>
          <NewsMostReadBlockTitle>Meistgelesen</NewsMostReadBlockTitle>
          <NewsMostReadBlock />
        </FixBlock>
      </Suspense>
    </LazyLoad>
  )
}
