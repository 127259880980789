import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsTripletPlaceholder
  from "../../News/NewsTriplet/NewsTripletPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import resolvePath from "../../../utils/resolvePath";
import {IMAGE_ONE_THIRD} from "../../../utils/variables";
import {PUBLI_REPORTS} from "../../../utils/constants";
import BlockTitle from "../BlockTitle";

const NewsTriplet = lazy(() => import('../../News/NewsTriplet'));

export default function TripleImage({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 410 : 920;
  const EmptyEntry = () => {
    return (
      <Block>
        <NewsTripletPlaceholder />
        <NewsTripletPlaceholder />
        <NewsTripletPlaceholder />
      </Block>
    )
  }

  if (data.length === 0) {
    return <EmptyEntry />
  }

  const Entry = () => {
    return data.map(node => <NewsTriplet
      key={`news_${node.id}`}
      title={node.title}
      author={node.author}
      image={resolvePath(node, IMAGE_ONE_THIRD)}
      link={node.link}
      flag={node.flag}
      imageAlt={node.image_alt}
      imageTitle={node.image_title}
      newsType={node.shn_type}
      catchLine={node?.catchLine}
      paywall={node.paywall}
      publiReport={node.shn_type === PUBLI_REPORTS}
      showAuthor={node.showAuthor}
    />);
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<EmptyEntry />}>
        <BlockTitle title={title} />
        <Block>
          <Entry/>
        </Block>
      </Suspense>
    </LazyLoad>
  );
}
