import React from "react";
import {
  GalleryItem
} from "./GalleryStyle";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import {Grid3ImageWrapper} from "../../Images/ImageStyles";

export default function GalleryItemPlaceholder() {
  return (
    <GalleryItem>
      <Grid3ImageWrapper />
      <TitlePlaceholder />
    </GalleryItem>
  )
}
