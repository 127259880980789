import React from "react";
import NewsType from "../../NewsType";
import {ItemTitleSmall} from "../../../utils/titles";
import Title from "../../Title";
import {TimeAgo, TripletItemNarrow} from "./NewsTripletNarrowStyle";

export default function NewsTripletNarrow({ flag = null, currentTime, title, link, newsType, paywall, publishedTime, id = '', catchLine = null, imageAlt = '', imageTitle = '', image = '', showAuthor = false, author = [], publiReport = false }) {
  const timeConvert = () => {
    const num = parseInt(((currentTime - publishedTime) / 60).toFixed(0));
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    if (rhours > 0) {
      return `vor ${rhours} Stunde${(rhours > 1) ? 'n' : ''}`;
    }

    return `vor ${rminutes} Minute${(rminutes > 1) ? 'n' : ''}`;
  }

  return (
    <TripletItemNarrow className={publiReport ? 'publi' : null}>
      <NewsType newsType={newsType} catchLine={catchLine} flag={flag} />
      <ItemTitleSmall>
        <Title title={title} link={link} paywall={paywall} />
      </ItemTitleSmall>
      <TimeAgo className={'ago'}>{timeConvert()}</TimeAgo>
    </TripletItemNarrow>
  )
}
