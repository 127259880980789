import React from "react";
import {NewsTypeEmpty, TitleEmpty, TitlePlaceHolder} from "./TitleStyle";

export default function TitlePlaceholder() {
  return (
    <TitlePlaceHolder>
      <NewsTypeEmpty />
      <TitleEmpty />
    </TitlePlaceHolder>
  );
}
