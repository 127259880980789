import React from "react";
import TripletItem from "../../../utils/TripletItem";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";

export default function NewsTripletTextPlaceholder() {
  return (
    <TripletItem>
      <TitlePlaceholder />
    </TripletItem>
  )
}
