import styled from "styled-components";
import {FixBlock} from "../../Block";
import {animations} from "../../../utils/animations";
import {blockHover} from "../../../utils/blockHover";
import {textOnImageHover} from "../../../utils/textOnImageHover";
import breakpoints from "../../../utils/breakpoints";
import {
  blockMarginDesktopInt,
  blockMarginMobileInt
} from "../../../utils/margins";

export const NewsImageSingleWrapper = styled(FixBlock)`
  margin-bottom: ${blockMarginMobileInt - 10}px;
  @media ${breakpoints.tablet} {
    margin-bottom: ${blockMarginDesktopInt - 10}px;
  }
  h2 {
    font-size: 18px;
    @media ${breakpoints.desktop} {
      font-size: 1.875rem;
    }
  }
`;

export const NewsImageSingleBlock = styled.div`
  ${animations.image.unHover}
  ${blockHover}
  ${textOnImageHover}
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const NewsImageSinglePlaceholderBlock = styled(FixBlock)`
  display: inline-block;
  width: 100%;
  height: auto
  overflow: visible;
  > div {
    padding-bottom: 56.25%;
  }
`;
