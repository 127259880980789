import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsTripletTextPlaceholder
  from "../../News/NewsTripletText/NewsTripletPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsTripletText = lazy(() => import('../../News/NewsTripletText'));

export default function TripleText({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 410 : 620;
  const EmptyEntry = () => {
    return (
      <Block>
        <NewsTripletTextPlaceholder />
        <NewsTripletTextPlaceholder />
        <NewsTripletTextPlaceholder />
      </Block>
    )
  }

  if (data.length === 0) {
    return <EmptyEntry />
  }

  const Entry = () => {
    return data.map(node => <NewsTripletText key={`news_${node.id}`} node={node}/>);
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<EmptyEntry />}>
        <BlockTitle title={title} />
        <Block>
          <Entry/>
        </Block>
      </Suspense>
    </LazyLoad>
  );
}
