import React from 'react';
import styled from "styled-components";
import {LatoTitleSmall} from "../../../utils/titles";
import colors from "../../../utils/colors";

export const BlockTitleStyle = styled.h2`
  ${LatoTitleSmall}
  color: #333;
  padding: 0;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 1.1rem;
  margin-top: 0;
  &:before {
    width: 230px;
    border-top: 1px solid #333;
    display: block;
    padding-bottom: 5px;
    position: relative;
    top: 0;
    left: 0;
    content: '';
  }
`;

export const BlockTitleStylePlaceholder = styled(BlockTitleStyle)`
  color: #333;
  width: 100px;
  height: 16px;
  border-top: 7px solid #fff;
  background-color: ${colors.placeholderGrey};
  &:before {
    top: -7px;
    padding-bottom: 6px;
    border-color: ${colors.placeholderGrey};
  }
`;

export default function BlockTitle({ title = '' }) {
  if (title.length === 0) {
    return <></>;
  }

  return <BlockTitleStyle>{title}</BlockTitleStyle>;
}
