import colors from "./colors";

export const blockBorder = `
  border-top: 1px solid ${colors.placeholderGrey};
`;

export const blockBorderBefore = `
  &:before {
    display: block;
    width: 100%;
    position: relative;
    content: '';
    padding-top: 1rem;
    ${blockBorder}
  }
`;
