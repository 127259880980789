import React from "react";
import {
  Grid2LargeImageWrapper
} from "../../Images/ImageStyles";
import {Block} from "../../Block";
import TripletItem from "../../../utils/TripletItem";
import NewsTripletPlaceholder from "../NewsTriplet/NewsTripletPlaceholder";
import BlockTitle from "../../Layouts/BlockTitle";

export default function NewsImageDualComboPlaceholder() {
  return (
    <Block>
      <div>
        <Grid2LargeImageWrapper />
      </div>
      <TripletItem>
        <NewsTripletPlaceholder />
      </TripletItem>
    </Block>
  )
}
