import styled from "styled-components";
import {TripletItemInner} from "../../../utils/TripletItem";
import colors from "../../../utils/colors";
import breakpoints from "../../../utils/breakpoints";

export const TripletItemNarrow = styled(TripletItemInner)`
  width: 260px;
  padding-right: 30px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .hidden-mobile {
    display: none;
  }

  span {
    margin-top: 0;
  }

  & > span {
    font-size: 13px;
  }

`;

export const TimeAgo = styled.div`
  align-self: flex-end;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  padding-top: 10px;
  font-size: 12px;
  color: ${colors.textGrey};
`;
