import React from "react";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import { NewsImageDualItem } from "../NewsImageDual/NewsImageDualItem";

export default function NewsTextDualPlaceholder() {
  return (
    <NewsImageDualItem>
      <TitlePlaceholder />
    </NewsImageDualItem>
  )
}
