import React from "react";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import {
  Grid2LargeImageWrapper
} from "../../Images/ImageStyles";
import {Block} from "../../Block";
import TripletItem from "../../../utils/TripletItem";
import {SideTriplets} from "./NewsImageTripletComboStyles";

export default function NewsImageTripleComboPlaceholder() {
  return (
    <Block>
      <div>
        <Grid2LargeImageWrapper />
      </div>
      <SideTriplets>
        <TripletItem>
          <TitlePlaceholder />
        </TripletItem>
        <TripletItem>
          <TitlePlaceholder />
        </TripletItem>
      </SideTriplets>
    </Block>
  )
}
