import styled from "styled-components";
import colors from "../../utils/colors";

export const TitlePlaceHolder = styled.div`
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
`;

export const TitleEmpty = styled.span`
  display: block;
  width: 100%;
  height: 1.2rem;
  background-color: ${colors.placeholderGrey};
  margin: 0.7rem 0;
`;

export const TitleEmptyShort = styled(TitleEmpty)`
  width: 40%;
`;

export const NewsTypeEmpty = styled.span`
  display: block;
  width: 80px;
  height: 0.8rem;
  background-color: ${colors.placeholderGrey};
  margin: 0.7rem 0;
`;
