import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsImageDualPlaceholder
  from "../../News/NewsImageDual/NewsImageDualPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsDualImageAdRight = lazy(() => import('../../News/NewsImageDualAdRight'));
const id = Math.floor((Math.random() * 100) + 1);
export default function DualImageAdRight({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 510 : 920;

  const EmptyEntry = () => {
    return (
      <Block>
        <NewsImageDualPlaceholder />
        <NewsImageDualPlaceholder />
      </Block>
    )
  }

  if (data.length === 0) {
    return <EmptyEntry />
  }

  const Entry = () => {
    return <NewsDualImageAdRight key={`news_${id}`} data={data}/>;
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<EmptyEntry />}>
        <BlockTitle title={title} />
        <Entry/>
      </Suspense>
    </LazyLoad>
  );
}
