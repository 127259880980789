import React, {useEffect, useState} from "react";
import {
  MostReadIndex,
  MostReadItemStyle,
} from "../NewsMostRead/NewsMostReadStyles";
import {API_PATH, HOST} from "../../../utils/constants";
import {ItemTitleSmall} from "../../../utils/titles";
import TitlePlaceholderSingle from "../../Placeholders/TitlePlaceholderSingle";

import {
  MostLikedAndReadBlockNews,
  MostLikedAndReadContent,
  PaddleArea,
  SwitchPaddle
} from "./NewsMostLikedAndReadStyles";
import NewsMostSomethingItem from "./NewsMostSomethingItem";
import BlockTitle from "../../Layouts/BlockTitle";
import {FixBlock} from "../../Block";

const mostRead = 'most_read';
const mostLiked = 'most_liked';

export default function NewsMostLikedAndRead({ title = 'TESTING' }) {
  const [activeItems, setActiveItems] = useState([]);
  const [activePaddle, setActivePaddle] = useState(mostRead);
  const [renderedActiveItems, setRenderedActiveItems] = useState([]);
  const [mostLikedNews, setMostLikedNews] = useState([]);
  const [mostReadNews, setMostReadNews] = useState([]);

  const fetchSetMostRead = () => {
    fetch(`${HOST}/${API_PATH}/most_read`)
      .then(response => response.json())
      .then(data => setMostReadNews(data))
      .then(fetchSetMostLiked);
  }

  const fetchSetMostLiked = () => {
    fetch(`${HOST}/${API_PATH}/most_liked`)
      .then(response => response.json())
      .then(data => setMostLikedNews(data));
  }

  useEffect(() => {
    fetchSetMostRead();
  }, []);

  useEffect(() => {
    setRenderedActiveItems(NewsMostSomethingBlock());
  }, [activeItems]);

  useEffect(() => {
    if (activePaddle === mostRead) {
      setActiveItems(mostReadNews);
    }
    if (activePaddle === mostLiked) {
      setActiveItems(mostLikedNews)
    }
  }, [activePaddle]);

  useEffect(() => {
    if (activeItems.length === 0 && mostReadNews.length > 0) {
      setActiveItems(mostReadNews);
    }
  }, [mostReadNews])


  const EmptyEntries = () => {
    return [...Array(6)].map((e, index) => {
      return (
        <MostReadItemStyle key={`${index}_empty_something_item`}>
          <MostReadIndex>{index + 1}</MostReadIndex>
          <ItemTitleSmall>
            <TitlePlaceholderSingle/>
          </ItemTitleSmall>
        </MostReadItemStyle>
      );
    });
  }

  const EmptyBlock = () => {
    return (
      <FixBlock>
        <BlockTitle title={title} />
        <MostLikedAndReadBlockNews>
          <PaddleArea>
            <SwitchPaddle key={mostRead} onClick={() => paddleClickHandler(mostRead)} className={activePaddle === mostRead ? 'option-active' : null}>Meistgelesene Artikel</SwitchPaddle>
            <SwitchPaddle key={mostLiked} onClick={() => paddleClickHandler(mostLiked)} className={activePaddle === mostLiked ? 'option-active' : null}>Beliebteste Artikel</SwitchPaddle>
          </PaddleArea>
          <MostLikedAndReadContent>
            <EmptyEntries />
          </MostLikedAndReadContent>
        </MostLikedAndReadBlockNews>
      </FixBlock>
    );
  }

  const NewsMostSomethingBlock = () => {
    return activeItems.map((news, index) => {
      return <NewsMostSomethingItem key={`news_${activePaddle}_news_${news.id}`} news={news} index={index + 1}/>;
    });
  }

  const paddleClickHandler = (paddle) => {
    if (paddle === activePaddle) {
      return null;
    }
    setActivePaddle(paddle);
  }

  if (activeItems.length === 0) {
    return <EmptyBlock />;
  }

  return (
    <FixBlock>
      <BlockTitle title={title} />
      <MostLikedAndReadBlockNews>
        <PaddleArea>
          <SwitchPaddle key={mostRead} onClick={() => paddleClickHandler(mostRead)} className={activePaddle === mostRead ? 'option-active' : null}>Meistgelesene Artikel</SwitchPaddle>
          <SwitchPaddle key={mostLiked} onClick={() => paddleClickHandler(mostLiked)} className={activePaddle === mostLiked ? 'option-active' : null}>Beliebteste Artikel</SwitchPaddle>
        </PaddleArea>
        <MostLikedAndReadContent>
          {renderedActiveItems}
        </MostLikedAndReadContent>
      </MostLikedAndReadBlockNews>
    </FixBlock>
  )
}
