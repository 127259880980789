import styled from "styled-components";
import colors from "../../../utils/colors";
import { SectionTitle } from "../../../utils/titles";
import {
  desktopBlockMargin,
  mobileItemMargin, tabletItemMargin
} from "../../../utils/margins";
import breakpoints from "../../../utils/breakpoints";
import {animations} from "../../../utils/animations";
import {blockHover} from "../../../utils/blockHover";

export const GalleryBlockStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  background-color: ${colors.placeholderGrey};
  max-width: 960px;
  margin: 0 auto;
  justify-content: space-evenly;
  padding-bottom: 2rem;
  ${mobileItemMargin}
  ${desktopBlockMargin}

  h2 {
    margin-top: 0.75em;
  }
`;

export const GalleryBlockInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  justify-content: space-evenly;
  @media ${breakpoints.tabletOnly} {
    display: block;
  }
`;

export const GalleryItem = styled.div`
  width: 300px;
  @media ${breakpoints.tabletOnly} {
    width: 360px;
    margin: 0 auto;
  }
  ${mobileItemMargin}
  ${tabletItemMargin}
  ${animations.image.unHover}
  ${blockHover}

   &:last-child {
    margin-bottom: 0;
  }
`;


export const GalleryTitle = styled(SectionTitle)`
  text-align: center;
`;
