import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import { LatoTitleSmall } from "../../utils/titles";
import breakpoints from "../../utils/breakpoints";

export const NewsTypeStyle = styled.span`
  ${LatoTitleSmall}
  color: ${colors.shnBlue};
  margin: 0.75em 0 0.417em;
  padding: 0;
  font-size: 14px;
  &.type {
    font-size: 12px;
    text-transform: uppercase;
  }
  &.white {
    color: #ffffff;
    @media screen and (${breakpoints.desktop}) {
      margin-bottom: 0.2em;
    }
  }
`;

export const NewsFlagStyle = styled.div`

  span {
    ${LatoTitleSmall}
    display: inline-block;
    color: ${colors.shnBlue};
    margin: 0.5em 0 0.417em;
    padding: 0.3em 0.75em 0.2em;
    font-size: 14px;
    width: fit-content;
  }
`;

export default function NewsType({ newsType, catchLine = null, color = '', flag = null}) {
  if (flag !== null) {
    return <NewsFlag flag={flag} />;
  }
  const hasCatchLine = catchLine !== null;
  let titleClass = '';
  if (!hasCatchLine || catchLine.length === 0) {
    titleClass = 'type';
  }
  return (
    <NewsTypeStyle className={[titleClass, color]}>{hasCatchLine && catchLine.length ? catchLine : newsType}</NewsTypeStyle>
  );
}

export function NewsFlag({ flag }) {
  return (
    <NewsFlagStyle className="flag">
      <span style={{ backgroundColor: flag.color, color: flag.text_color }}>{flag.label ?? flag.name}</span>
    </NewsFlagStyle>
  );
}
