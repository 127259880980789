import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsImageTripleComboPlaceholder
  from "../../News/NewsImageTripleCombo/NewsImageTripleComboPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsTripleCombo = lazy(() => import('../../News/NewsImageTripleCombo'));

export default function TripleImageCombo({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 410 : 620;
  if (data.length === 0) {
    return <NewsImageTripleComboPlaceholder />
  }
  const Entry = () => {
    return (
      <NewsTripleCombo key={`news_${data[0].id}`} data={data}/>
    );
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<NewsImageTripleComboPlaceholder />}>
        <BlockTitle title={title} />
        <Block>
          <Entry/>
        </Block>
      </Suspense>
    </LazyLoad>
  );
}
