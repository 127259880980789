import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsImageDualComboPlaceholder
  from "../../News/NewsImageDualCombo/NewsImageDualComboPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsDualImageCombo = lazy(() => import('../../News/NewsImageDualCombo'));

export default function DualImageCombo({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 510 : 520;
  if (data.length === 0) {
    return <NewsImageDualComboPlaceholder />
  }

  const Entry = () => {
    return (
      <NewsDualImageCombo key={`news_${data[0].id}`} data={data}/>
    );
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<NewsImageDualComboPlaceholder />}>
        <BlockTitle title={title} />
        <Block>
          <Entry/>
        </Block>
      </Suspense>
    </LazyLoad>
  );
}
