import React, {useContext} from "react";
import {PaywallContext} from "../LayoutHub";
import styled from "styled-components";
import {ReactComponent as  LockClosed} from './lock-solid.svg'
import {ReactComponent as LockOpen} from './unlock-solid.svg'
import colors from "../../utils/colors";

const PaywallIcon = styled.div`
  height: 1em;
  width: 18px;
  display: inline-flex;
  align-items: center;
  position: relative;
  bottom: -2px;

  svg {
    font-size: 22px;
    height: 18px;
    width: 13px;
    path {
      fill: ${colors.shnBlue};
    }
  }
  &.lock_grey {
   svg path {
      fill: ${colors.textGrey};
    }
  }
  &.lock_small {
    svg {
      font-size: 16px;
      height: 13px;
      width: 9px;
    }
  }
`;

export default function PaywallLock({ itemStatus, type = 'inline', colorOverride = '' }) {
  const paywallIcon = useContext(PaywallContext);

  if (itemStatus === 'paid' && paywallIcon !== null) {
    return <PaywallIcon className={colorOverride}>{paywallIcon ? <LockOpen /> : <LockClosed />}</PaywallIcon>
  }

  return (
      <></>
  )
}
