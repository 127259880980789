import styled from "styled-components";
import {blockBorder} from "../../../utils/borders";
import {mobileItemMargin} from "../../../utils/margins";
import breakpoints from "../../../utils/breakpoints";
import {animations} from "../../../utils/animations";
import {blockHover} from "../../../utils/blockHover";

export const NewsImageDualItem = styled.div`
  display: inline-block;
  width: 100%;
  height: auto;
  max-width: 460px;
  &:before {
    ${blockBorder}
    width: 100%;
    display: block;
    content: '';
    margin-bottom: -1px;
  }
  ${mobileItemMargin}
  ${animations.image.unHover}
  ${blockHover}
  @media ${breakpoints.tabletOnly} {
    max-width: 48%;
  }
`;

export const NewsItemDualAd = styled(NewsImageDualItem)`
  &:before {
    border: 0;
  }
`;


export default { NewsImageDualItem, NewsItemDualAd };
