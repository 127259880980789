import React from "react";
import {
  NewsTypeEmpty,
  TitleEmpty,
  TitleEmptyShort,
  TitlePlaceHolder
} from "./TitleStyle";

export default function TitlePlaceholder() {
  return (
    <TitlePlaceHolder>
      <NewsTypeEmpty />
      <TitleEmpty />
      <TitleEmptyShort />
    </TitlePlaceHolder>
  );
}
