import React, {lazy, Suspense} from "react";
import GalleryBlockPlaceholder from "./GalleryBlockPlaceholder";
import LazyLoad from 'react-lazyload';
import {breakpointsRaw} from "../../../utils/breakpoints";

const GalleryBlock = lazy(() => import('./GalleryBlock'));

export default function Galleries() {
  const height = window.screen.width > breakpointsRaw.desktop ? 400 : 765;
  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<GalleryBlockPlaceholder />}>
        <GalleryBlock />
      </Suspense>
    </LazyLoad>
  )
}
