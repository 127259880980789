import colors from "./colors";

export const textOnImageHover = `
  &:hover {
    h2 span,
    h2 {
      color: ${colors.textAltHover}
    }
  ≠}
`;
