import React, {lazy, Suspense} from "react";
import { FixBlock } from "../../Block";
import LazyLoad from 'react-lazyload';
import RadioMunotBlockPlaceholder from "./RadioMunotBlockPlaceholder";
import BlockTitle from "../BlockTitle";

const RadioMunotAdvertBlock = lazy(() => import('./RadioMunotAdvertBlock'));

export default function RadioMunotAdvert({ data = '', title = '' }) {

  const EmptyRadioMunotAdvertBlock = () => {
    return (
      <FixBlock>
        <RadioMunotBlockPlaceholder />
      </FixBlock>
    );
  }

  return (
    <LazyLoad height={461}>
      <BlockTitle title={title} />
      <Suspense fallback={<EmptyRadioMunotAdvertBlock />}>
        <RadioMunotAdvertBlock advert={data}/>
      </Suspense>
    </LazyLoad>
  )
}

