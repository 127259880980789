import React from "react";
import TripletItem from "../../../utils/TripletItem";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import {Grid3ImageWrapper} from "../../Images/ImageStyles";

export default function NewsTripletPlaceholder() {
  return (
    <TripletItem>
      <Grid3ImageWrapper />
      <TitlePlaceholder />
    </TripletItem>
  )
}
