import React, {lazy, Suspense} from 'react';
import LazyLoad from 'react-lazyload';
import NewsTextSinglePlaceholder
  from "../../News/NewsTextSingle/NewsTextSinglePlacholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsTextSingleBlock = lazy(() => import('../../News/NewsTextSingle'));

export default function NewsTextSingle({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 110 : 320;
  if (data.length === 0) {
    return (
      <NewsTextSinglePlaceholder />
    )
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<NewsTextSinglePlaceholder />}>
        <BlockTitle title={title} />
        <NewsTextSingleBlock node={data[0]} />
      </Suspense>
    </LazyLoad>
  )
}
