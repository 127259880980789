import React from "react";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import {VideoTile} from "./VideoTile";
import styled from "styled-components";
import colors from "../../../utils/colors";

const EmptyVideoTile =  styled.div`
  width: 100%;
  max-width: 300px;
  height: 169px;
  display: block;
  background-color: ${colors.placeholderGrey};
`;

export default function VideoPlaceHolder() {
  return (
    <VideoTile>
      <EmptyVideoTile />
      <TitlePlaceholder />
    </VideoTile>
  )
}
