import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";
import {mobileItemMargin, tabletItemMargin} from "../../../utils/margins";
import {blockHover} from "../../../utils/blockHover";

export const VideoTile = styled.div`
  position: relative;
  display: inline-block;
  width: 300px;

  @media ${breakpoints.tabletOnly} {
    display: block;
    width: 360px;
    margin: 0 auto;
  }
  ${mobileItemMargin}
  ${tabletItemMargin}
  ${blockHover}

   &:last-child {
    margin-bottom: 0;
  }
`;
