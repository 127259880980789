import React, {lazy, Suspense} from "react";
import { FixBlock } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsLatestPlaceholder from "./NewsLatestPlaceholder";

const NewsLatestBlock = lazy(() => import('./NewsLatestBlock'));

export default function NewsLatest({ data = '' }) {

  const EmptyNewsLatestBlock = () => {
    return (
      <FixBlock>
        <NewsLatestPlaceholder />
      </FixBlock>
    );
  }

  return (
    <LazyLoad height={461}>
      <Suspense fallback={<EmptyNewsLatestBlock />}>
        <NewsLatestBlock advert={data}/>
      </Suspense>
    </LazyLoad>
  )
}

