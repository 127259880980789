import React from "react";
import {NewsImageSingleWrapper} from "../../Images/ImageStyles";
import {
  NewsImageSinglePlaceholderBlock
} from "./NewsImageSingleStyle";

export default function NewsImageSinglePlaceholder() {
  return (
    <NewsImageSinglePlaceholderBlock>
      <NewsImageSingleWrapper />
    </NewsImageSinglePlaceholderBlock>
  )
}
