import React, {useEffect, useState} from 'react';
import LayoutHub from "./components/LayoutHub";
import styled from "styled-components";
import breakpoints from "./utils/breakpoints";
import {API_PATH, HOST} from "./utils/constants";
import './index.css';
import {publiReportStyle} from "./utils/publi";

const FrontPage = styled.div`
  max-width: 360px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
  position: relative;

  @media ${breakpoints.tabletOnly} {
    max-width: 620px;
  }

  @media ${breakpoints.desktop} {
    max-width: 940px;
  }
  ${publiReportStyle}
`

const PlaceholderImage = styled.img`
  animation: quickFadeIn 1.5s normal forwards;
  display: block;
  margin: 10% auto 0;

  @keyframes quickFadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }
`;

function App() {
  const [layout, setLayout] = useState({});
  useEffect(() => {
    fetch(`${HOST}/${API_PATH}/layout`)
      .then(response => response.json())
      .then(data => setLayout(data));
  }, [])

  if (Object.entries(layout).length === 0) {
    return <PlaceholderImage src={'https://www.shn.ch/sites/default/files/shn_logo_0.png'} alt={'Schaffhauser Nachrichten lädt'} />
  }

  return (
    <FrontPage className="App">
      <LayoutHub layout={layout} />
    </FrontPage>
  );
}

export default App;
