import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";
import {ItemTitleSmall, SectionTitle} from "../../../utils/titles";
import colors from "../../../utils/colors";
import {animations} from "../../../utils/animations";


export const ItemTitleSmallMostAll = styled(ItemTitleSmall)`
  span {
    font-size: 12px;
    line-height: 1.3em;
  }
  a {
    font-size: 14px;
    line-height: 1.3em;
  }
`;

export const MostLikedAndReadBlockNews = styled.div`
  @media ${breakpoints.desktop} {
    grid-template-columns: 33% 1fr;
    display: grid;
  }
`;
export const MostLikedAndReadContent = styled.div`
  position: relative;
  display: grid;
  gap: 1rem 50px;
  @media ${breakpoints.desktop} {
    grid: repeat(3,1fr) / repeat(2, 1fr);
    grid-auto-flow: column;
  }
`;
export const PaddleArea = styled.div`
  display: flex;
  width: 100%;
  @media ${breakpoints.desktop} {
    display: block;
    width: 90%;
  }
`;
export const SwitchPaddle = styled.div`
  ${animations.all.swift}
  cursor: pointer;
  width: 200px;
  margin-bottom: 1rem;
  display: flex;
  background-color: ${colors.white};
  color: ${colors.textGrey};
  position: relative;
  height: 50px;
  align-items: center;
  padding: 0 0.6rem;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  font-size: 14px;

  &.option-active {
    background-color: ${colors.placeholderGrey};
    &:after {
      border-left-color: ${colors.placeholderGrey};
    }

    &:hover {
      color: ${colors.textGrey};
      background-color: ${colors.placeholderGrey};
      &:after {
        border-left-color: ${colors.placeholderGrey};
      }
    }
  }

  &:hover {
    color: ${colors.white};
    background-color: ${colors.shnBlue};
    &:after {
      border-left-color: ${colors.shnBlue};
    }
  }


  @media ${breakpoints.desktop} {
    padding: 0 0 0 1rem;
    margin-bottom: 0.2rem;
    font-size: 16px;
    &:after {
      ${animations.all.swift}
      width: 0;
      height: 0;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 25px solid ${colors.white};
      display: block;
      content: '';
      position: absolute;
      right: -25px;
    }
  }

  &:active {
    opacity: 0.8;
  }

`;
export const MostSomethingItemStyle = styled.div`
  display: grid;
  min-height: 42px;
  grid-template-columns: 50px 1fr;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  h2 > span {
    margin-top: 0;
  }

  animation-name: animateIn;
  animation-duration: 0.15s;
  animation-fill-mode: both;

  @keyframes animateIn {
    0% {transform: translateX(-40px); opacity: 0.5}
    100% {transform: translateX(0px); opacity: 1}
  }
`;
export const MostSomethingIndex = styled.div`
  width: 30px;
  display: inline;
  text-align: right;
  margin-right: 20px;
  float: left;
  font-family: 'Sole-Headline', serif;
  font-size: 1.875rem;
  line-height: 1em;
  color: ${colors.text};

`;
export const NewsMostReadBlockTitle = styled(SectionTitle)`
  text-align: center;
`;
