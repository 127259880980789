export const colors = {
  background: 'rgb(224,224,224)',
  placeholderGrey: '#EEEEEE',
  shnBlue: '#337ab7',
  text: '#333333',
  textGrey: '#7D7D7D',
  textAltHover: '#D8D8D8',
  gold: '#a3956a',
  white: '#fff',
  red: '#c23333',
  orange: '#f59c00',
};

export default colors;
