import styled from "styled-components";
import colors from "./colors";
import {blockBorderBefore} from "./borders";
import breakpoints from "./breakpoints";

const generalTitle = `
  text-align: left;
  font-family: 'Sole-Headline', serif;
  width: 100%;
  margin: 0;
  color: ${colors.text};
  font-weight: 900;
  font-style: normal;
`;

export const LatoTitleSmall = `
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.2px;
  font-size: 0.75rem;
  padding: 0 0 1rem;
  display: block;
`;

export const ItemTitleSmall = styled.h2`
  ${generalTitle}
  text-align: left;
  font-size: 1rem;
  line-height: 1.2em;
  font-weight: 600;
`;

export const ItemTitleMedium = styled.h2`
 ${generalTitle}
 font-size: 1rem;
 line-height: 1.2em;
 display: block;
 @media ${breakpoints.desktop} {
   font-size: 22px;
 }
`;

export const ItemTitleMediumLarge = styled.h2`
  ${generalTitle}
  font-size:1.875rem;
  line-height: 1.2em;
`;

export const ItemTitleLarge = styled.h2`
 ${generalTitle}
 margin-top: 0.2em;
 line-height: 1.2em;

 @media ${breakpoints.desktop} {
   font-size: 2.75rem;
 }
`;

export const SectionTitle = styled.span`
  color: ${colors.text};
  ${LatoTitleSmall}
  ${blockBorderBefore}
  text-transform: uppercase;
`;

export const ItemTitleMobileSmall = styled(ItemTitleSmall) `
  font-size: 14px;
  @media ${breakpoints.screen} {
    font-size: 1rem;
  }
`;

export const ItemTitleMediumMobileSmall = styled(ItemTitleMedium) `
  font-size: 14px;
  @media ${breakpoints.desktop} {
    font-size: 22px;
  }
`;

export const ItemTitleLargeMobileSmall = styled(ItemTitleLarge) `
  font-size: 16px;
  @media ${breakpoints.desktop} {
    font-size: 2.75rem;
  }
`;

export const ItemTitleMediumCombo = styled(ItemTitleMedium) `
  font-size: 18px;
  @media ${breakpoints.desktop} {
   font-size: 22px;
 }
`;
