import React, {lazy, Suspense} from 'react';
import LazyLoad from 'react-lazyload';
import NewsImageSinglePlaceholder
  from "../../News/NewsImageSingle/NewsImageSinglePlacholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsImageSingleBlock = lazy(() => import('../../News/NewsImageSingle'));

export default function NewsImageSingle({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 630 : (window.screen.width / 16 * 9 + 300);
  if (data.length === 0) {
    return (
      <NewsImageSinglePlaceholder />
    )
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<NewsImageSinglePlaceholder />}>
        <BlockTitle title={title} />
        <NewsImageSingleBlock node={data[0]} />
      </Suspense>
    </LazyLoad>
  )
}
