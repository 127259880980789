import React, { useEffect, useState} from 'react';
import LayoutContent from "./LayoutContent";
import {API_PATH, HOST} from "../../utils/constants";

export const PaywallContext = React.createContext({});

export default function LayoutHub({ layout }) {
  const [nodesLoaded, setNodesLoaded] = useState(false);
  const [paywallAccess, setPaywallAccess] = useState(null);
  const [frontPageData, setFrontPageData] = useState([]);
  const [scrollSet, setScrollSet] = useState(false);

  useEffect(() => {
    const options = {
      credentials: 'include',
    };
    fetch(`${HOST}/${API_PATH}/paywall`, options)
      .then(response => response.json())
      .then(data => setPaywallAccess(data))
  }, []);

  useEffect(() => {
    if (!nodesLoaded) {
      fetch(`${HOST}/${API_PATH}/data`)
        .then(response => response.json())
        .then((data) => setFrontPageData(data))
        .then(() => setNodesLoaded(true));
    }
    if (nodesLoaded && !scrollSet) {
      const scrollPosition = localStorage.getItem('scrollPos');
      localStorage.removeItem('scrollPos');
      if (scrollPosition) {
        setTimeout(() => {
          window.scroll({
            top: scrollPosition,
            behavior: 'smooth'
          });
        }, 300);
      }
      setScrollSet(true);
    }
  }, [layout, nodesLoaded]);

  return (
    <PaywallContext.Provider value={paywallAccess}>
      <LayoutContent data={frontPageData} layout={layout.order}/>
    </PaywallContext.Provider>
  )

}

