import styled from "styled-components";
import breakpoints from "../../utils/breakpoints";
import {
  desktopBlockMargin,
  mobileItemMargin
} from "../../utils/margins";

export const Block = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  @media ${breakpoints.desktop} {
    flex-wrap: nowrap;
  }
  ${desktopBlockMargin}
`;

export const FixBlock = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  ${mobileItemMargin}
  ${desktopBlockMargin}
`;

export default { Block, FixBlock };
