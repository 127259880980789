import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";
import {SectionTitle} from "../../../utils/titles";
import colors from "../../../utils/colors";

export const MostReadBlockNews = styled.div`
  @media ${breakpoints.desktop} {
    column-count: 2;
    column-gap: 50px;
  }
`;
export const MostReadItemStyle = styled.div`
  display: grid;
  min-height: 42px;
  grid-template-columns: 50px auto;
  margin-bottom: 1em;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
`;
export const MostReadIndex = styled.div`
  width: 30px;
  display: inline;
  text-align: right;
  margin-right: 20px;
  float: left;
  font-family: 'Sole-Headline', serif;
  font-size: 1.875rem;
  line-height: 1em;
  color: ${colors.text};
`;
export const NewsMostReadBlockTitle = styled(SectionTitle)`
  text-align: center;
`;
