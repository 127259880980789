import React, {lazy, Suspense, useEffect} from "react";
import { Block } from "../../Block";
import VideoPlaceHolder from "./VideoPlaceHolder";
import LazyLoad from 'react-lazyload';
import {breakpointsRaw} from "../../../utils/breakpoints";

const VideosBlock = lazy(() => import('./VideosBlock'));

export default function Videos() {
  const height = window.screen.width > breakpointsRaw.desktop ? 400 : 765;
  useEffect(() => {
    // Prefetch jwPlayer script.
    const playerScript = document.createElement('script');
    playerScript.src = process.env.REACT_APP_JWPLAYER_URL;
    playerScript.async = false;
    document.body.appendChild(playerScript);
  }, []);

  const EmptyVideoBlock = () => {
    return (
      <Block>
        <VideoPlaceHolder />
        <VideoPlaceHolder />
        <VideoPlaceHolder />
      </Block>
    );
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<EmptyVideoBlock />}>
        <VideosBlock />
      </Suspense>
    </LazyLoad>
  )
}

