import React from "react";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import {
  Grid2LargeImageWrapper
} from "../../Images/ImageStyles";
import {Block} from "../../Block";
import TripletItem from "../../../utils/TripletItem";

export default function NewsImageDualComboTextPlaceholder() {
  return (
    <Block>
      <div>
        <Grid2LargeImageWrapper />
      </div>
      <TripletItem>
        <TitlePlaceholder />
      </TripletItem>
    </Block>
  )
}
