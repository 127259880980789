import React, {Suspense, lazy} from "react";
import LazyLoad from "react-lazyload";
import {FixBlock} from "../../Block";
import {
  MostReadIndex, MostReadItemStyle,
  NewsMostReadBlockTitle
} from "../NewsMostRead/NewsMostReadStyles";
import {ItemTitleSmall} from "../../../utils/titles";
import {TitlePlaceHolder} from "../../Placeholders/TitleStyle";

const NewsMostLikedBlock = lazy(() => import('./NewsMostLikedBlock'));

export default function NewsMostLiked() {
  const Entries = () => {
    return [...Array(6)].map((e, index) => {
      return (
        <MostReadItemStyle>
          <MostReadIndex>{index + 1}</MostReadIndex>
          <ItemTitleSmall>
            <TitlePlaceHolder/>
          </ItemTitleSmall>
        </MostReadItemStyle>
      );
    });
  }

  const EmptyBlock = () => {
    return (
      <FixBlock>
        <NewsMostReadBlockTitle>Beliebteste Artikel</NewsMostReadBlockTitle>
        <Entries />
      </FixBlock>
    );
  }

  return (
    <LazyLoad height={480}>
      <Suspense fallback={<EmptyBlock />}>
        <FixBlock>
          <NewsMostReadBlockTitle>Beliebteste Artikel</NewsMostReadBlockTitle>
          <NewsMostLikedBlock />
        </FixBlock>
      </Suspense>
    </LazyLoad>
  )
}
