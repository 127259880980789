export const breakpointsRaw = {
  mobileSmall: 400,
  mobileLarge: 600,
  tablet: 720,
  desktop: 1200,
  bootstrapXs: 768,
}

const breakpoints = {
  mobileSmall: `screen and (min-width: ${breakpointsRaw.mobileSmall}px)`,
  mobileLarge: `screen and (min-width: ${breakpointsRaw.mobileLarge}px)`,
  tablet: `screen and (min-width: ${breakpointsRaw.tablet}px)`,
  bootstrapXs: `screen and (min-width: ${breakpointsRaw.bootstrapXs}px)`,
  tabletOnly: `screen and (min-width: ${breakpointsRaw.tablet}px) and (max-width: ${breakpointsRaw.desktop - 1}px)`,
  desktop: `screen and (min-width: ${breakpointsRaw.desktop}px)`,
}

export default breakpoints;
