import React, {lazy, Suspense} from 'react';
import { Block } from "../../Block";
import LazyLoad from 'react-lazyload';
import NewsImageDualComboTextPlaceholder
  from "../../News/NewsImageDualComboText/NewsImageDualComboTextPlaceholder";
import {breakpointsRaw} from "../../../utils/breakpoints";
import BlockTitle from "../BlockTitle";

const NewsDualImageComboText = lazy(() => import('../../News/NewsImageDualComboText'));

export default function DualImageComboText({ data = [], title = '' }) {
  const height = window.screen.width > breakpointsRaw.desktop ? 510 : 520;
  if (data.length === 0) {
    return <NewsImageDualComboTextPlaceholder />
  }

  const Entry = () => {
    return (
      <NewsDualImageComboText key={`news_${data[0].id}`} data={data}/>
    );
  }

  return (
    <LazyLoad height={height} offset={300}>
      <Suspense fallback={<NewsImageDualComboTextPlaceholder />}>
        <BlockTitle title={title} />
        <Block>
          <Entry/>
        </Block>
      </Suspense>
    </LazyLoad>
  );
}
