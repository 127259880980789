import React from 'react';
import InnerHTML from 'dangerously-set-html-content'
import { FixBlock } from "../../Block";
import LazyLoad from "react-lazyload";
import BlockTitle from "../BlockTitle";

export default function FullText({ data = [], title = '' }) {
  if (data.length === 0) {
    return <></>
  }

  return (
    <LazyLoad height={'100%'}>
      <BlockTitle title={title} />
      <FixBlock>
        <InnerHTML html={data} />
      </FixBlock>
    </LazyLoad>
  );
}
