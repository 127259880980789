import styled from "styled-components";
import {Block} from "../../Block";
import colors from "../../../utils/colors";
import breakpoints, {breakpointsRaw} from "../../../utils/breakpoints";

export const BlockGrid = styled(Block)`

  overflow: hidden;
  width: 100%;
  display: block;
  margin-bottom: 2rem;
  position: relative;
`;

export const ButtonBlue = styled.a`
  margin-right: 80px;
  display: block;
  background-color: ${colors.shnBlue};
  color: ${colors.white};
  padding: 10px 15px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
  font-weight: 900;
`;

export const ScrollIntent = styled.div`
  height: 100%;
  position: absolute;
  width: 70px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%);
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  pointer-events: none;
  z-index: 1;
`;

export const MobileScrollIntentHide = styled.div`
  width: 60px;
    height: auto;
    margin-left: -60px;
    background: linear-gradient(-90deg,rgb(255 255 255) 40%,rgb(255 255 255 / 0%) 100%);
    z-index: 10;

`;
