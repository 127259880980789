import React from "react";
import {
  UnStyledTitleLink,
  UnStyledTitleNonLink
} from "../../utils/UnStyledLink";
import PaywallLock from "../PaywallLock";
import setScrollPosition from "../../utils/setScrollPosition";

export default function Title({ title, link = null, paywall, lockSize = '' }) {
  if (!link) {
    return (
      <>
        <UnStyledTitleNonLink>{title}</UnStyledTitleNonLink>
        <PaywallLock itemStatus={paywall} colorOverride={lockSize}/>
      </>
    )
  }

  return (
    <>
     <UnStyledTitleLink href={link} onClick={(event) => setScrollPosition()}>{title}</UnStyledTitleLink>
     <PaywallLock itemStatus={paywall} colorOverride={lockSize} />
    </>
  )
}
