import styled from "styled-components";
import colors from "../../utils/colors";
import breakpoints from "../../utils/breakpoints";
import {animations} from "../../utils/animations";

const grid3 = {
  width: '300px',
  height: '161px',
}

const grid2 = {
  width: '460px',
  height: '259px',
}

const grid2Large = {
  width: '620px',
  height: '349px',
}

const fullWidth = {
  width: '940px',
  height: '529px'
}

const wrapper = `
  background-color: ${colors.placeholderGrey};
  display: block;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
`;

export const ImageWrapper = styled.a`
  ${wrapper}
`;

export const Grid2ImageWrapper = styled(ImageWrapper)`
  width: 100%;

  @media ${breakpoints.tabletOnly} {
    max-width: 310px;
  }

  @media ${breakpoints.desktop} {
    width: ${grid2.width};
    max-width: 100%;
  }
`;

export const Grid3ImageWrapper = styled(ImageWrapper)`
  width: 100%;
  max-width: 100%;
  @media ${breakpoints.tabletOnly} {
    width: 360px;
    height: 202px;
    padding-bottom: 0;
    flex: 0 0 360px;
    margin-right: 20px;
  }

  @media ${breakpoints.desktop} {
    width: ${grid3.width};
  }
`;

export const NewsImageSingleWrapper = styled.div`
  ${wrapper}
  max-width: ${fullWidth.width};
  max-height: ${fullWidth.height};
  width: 100%;
`;

export const Grid2LargeImageWrapper = styled.div`
  ${wrapper}
  width: 100%;
  @media ${breakpoints.desktop} {
    width: ${grid2Large.width};
  }
`;

export const ImageHover = `
  ${animations.image.hover}
  filter: saturate(115%) brightness(80%);
`;

