import React from "react";
import { NewsImageDualItem } from "./NewsImageDualItem";
import TitlePlaceholder from "../../Placeholders/TitlePlaceholder";
import {Grid2ImageWrapper} from "../../Images/ImageStyles";

export default function NewsImageDualPlaceholder() {
  return (
    <NewsImageDualItem>
      <Grid2ImageWrapper />
      <TitlePlaceholder />
    </NewsImageDualItem>
  )
}
